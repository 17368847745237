import { graphql } from 'gatsby'
import React, { ReactElement, useState } from 'react'
import Seo from '../../components/Seo/Seo'
import './TermsAndConditions.scss'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../components/Layout/Layout'))

type TermsConditionsPropTypes = {
  data: {
    termsAndConditionsData: {
      title: string
      body: string
    }
  }
}

const TermsAndConditions = ({
  data: { termsAndConditionsData },
}: TermsConditionsPropTypes): ReactElement => {
  const [title] = useState(termsAndConditionsData?.title)

  return (
    <Layout>
      <Seo title="Terms and Conditions" />
      <div className="terms-and-conditions-container">
        <div className="terms-and-conditions">
          <h2 className="terms-and-conditions-title">{title}</h2>
          <div
            className="terms-and-conditions-content"
            dangerouslySetInnerHTML={{ __html: termsAndConditionsData?.body }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default TermsAndConditions

export const pageQuery = graphql`
  query {
    termsAndConditionsData: termsAndConditions {
      ...TermsAndConditionsFields
    }
  }
`
